<template>
  <div class="single-sys-common single-sys-user">
<!--    <div class="mb-2">-->
<!--      <el-button class="btn-primary" @click="goto()">新增</el-button>-->
<!--    </div>-->
    <div class="table-wrapper">
      <el-table
          :data="tableData"
          stripe
          height="calc(100% - 40px)"
          style="width: 100%"
          :header-cell-style="{ backgroundColor: '#E7F0FC' }"
          :header-row-style="{ color: '#3c3c3c' }"
          :row-style="{ color: '#5a5a5a', 'font-weight': 500 }"
      >
        <el-table-column
            prop="name"
            label="角色名称"
            show-overflow-tooltip
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            show-overflow-tooltip
            align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="opt-edit mr-6" @click="goto(scope.row)">编辑</span>
            <span class="opt-delete" @click="pageDel(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          layout="prev, pager, next"
          :total="pageParams.total"
          :page-size="pageParams.pageSize"
          :current-page="pageParams.pageNum"
          @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <!-- 新增和编辑弹框 -->
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
    >
      <div class="form-wrapper manage-scroll">
        <el-form
            :model="handleData"
            :rules="formRules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
        >
          <el-row :gutter="20" class="w-full">
            <el-col :span="24">
              <el-form-item label="角色名称" prop="name">
                <el-input v-model="handleData.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        pageParams: {
          total: 0,
          pageSize: 10,
          pageNum: 1,
        },
        bridgeList:[],
        tableData: [],
        dialogVisible: false,
        handleData: {
          bridgeIdList:[]
        },
        formRules: {
          name: [
            { required: true, message: '请填写角色名称', trigger: 'blur' }
          ]
        },
        title: "",
        dialogType: "",
        api:''
      };
    },
    created() {
    },
    methods: {
      initPage(){},
      currentChange(){},
      beforeDialogClose(){},
      resetFields(){},
      submit(){}
    },
  };
</script>
